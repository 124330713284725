import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import axios from 'axios';
import i18n from '@i18n/i18n';
import 'moment/min/locales.min';
import { boardmakerToSSOLanguage } from '@util/functions';
import { useLocation } from '@reach/router';
import productDownloadData from '@data/downloads';
import PageHeader from '@ui/PageHeader';
import SEO from '../components/seo';
import URLS from '@config/urls';
import { DownloadSelector } from '@components/Downloads';
import { ToggleButton, FullWidthLine } from '@ui/Downloads';

function ProductToggle({ products, activeProductIndex, setActiveProduct }) {
  const location = useLocation();
  //* preselect the tab
  useEffect(() => {
    if (location.hash.toLowerCase() === '#studentcenter') {
      setActiveProduct(1);
      activeProductIndex = 1; 
    }
    // eslint-disable-next-line
  }, []);

  var activeProduct = products[activeProductIndex];

  var legacyToggle = ``;
  if (products.filter(p => p.legacy).length > 0) {
    legacyToggle = (
      <ToggleButton
        id="LegacyToggle"
        key="-1"
        onClick={() => setActiveProduct(-1)}
        active={activeProductIndex === -1}
        className="top-toggle"
      >
        <h3>Legacy Software</h3>
      </ToggleButton>
    );
  }

  var currentKey = activeProduct ? activeProduct.key : -1;
  var toggles = ``;
  if (products) {
    toggles = products
      .filter(p => !p.legacy)
      .map((p, i) => (
        <ToggleButton
          id={p.key + 'Toggle'}
          key={p.key}
          onClick={() => setActiveProduct(i)}
          active={p.key === currentKey}
          className="top-toggle"
        >
          <h3>{p.heading}</h3>
        </ToggleButton>
      ));
    toggles.push(legacyToggle);
    return toggles;
  }

  return null;
}

function LegacyDownloadDisplay({ product, index }) {
  const [isActive, setIsActive] = useState(index === 0);
  return (
    <>
      <div className="card">
        <AccordionHeader
          className={
            isActive
              ? 'div-toggle-on card-header'
              : 'div-toggle-off card-header'
          }
          id={'heading' + index}
        >
          <h2 className="mb-0">
            <button
              className={
                isActive
                  ? 'btn-toggle-on btn btn-link btn-block text-left'
                  : 'btn-toggle-off btn btn-link btn-block text-left'
              }
              type="button"
              onClick={() => setIsActive(!isActive)}
              data-toggle="collapse"
              data-target={'#collapse' + index}
              aria-expanded="false"
              aria-controls={'collapse' + index}
            >
              {product.heading}
              <span className="btn-icon">
                {!isActive ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-down"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                    />
                  </svg>
                )}
              </span>
            </button>
          </h2>
        </AccordionHeader>
        <AccordionPanel
          id={'collapse' + index}
          className={isActive ? 'collapse show' : 'collapse'}
          aria-labelledby={'heading' + index}
          data-parent="#legacyAccordion"
        >
          <div className="card-body">
            <ProductDisplay product={product} type={1} />
          </div>
        </AccordionPanel>
      </div>
    </>
  );
}

function ProductDisplay({ product, type }) {
  const { t } = useTranslation('page');
  if (!product) {
    return null;
  }

  const productIntro = t('common:' + product.intro);
  return (
    <>
      <div className="row">
        <div
          className={
            type == 0
              ? 'col-10 small download-intro'
              : 'col-12 small download-intro'
          }
        >
          {productIntro}
        </div>
        {type == 0 && <div className="col-2"></div>}
        <div className="col-2"></div>
      </div>
      <div className="row download-requirements">
        <div className="col-12">
          <a
            className="small"
            href={product.requirements}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('systemRequirements')} 
          </a>{' '}
          {product.releaseNotes && (
            <>
              |{' '}
              <a
                className="small"
                href={product.releaseNotes}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('releaseNotes')}
              </a>
            </>
          )}
        </div>
      </div>
      {DownloadDisplay(product, t)}
    </>
  );
}
function DownloadDisplay(product, t) {
  return (
    <div className="row">
      {product.versions && (
        <>
          <DownloadSelector
            label={t('download.download')}
            versionData={product?.versions}
            setLabel={true}
          />
        </>
      )}
    </div>
  );
}

const DownloadsPage = function({ location }) {
  const { t } = useTranslation('page');
  const pageTitle = t('downloads');
  const introText = t('intro');
  const compatibleText = t('common:download.BoardmakerMobile');
      
  const [products, setProducts] = useState(productDownloadData);
  const [activeProductIndex, setActiveProduct] = useState(0);

  useEffect(() => {
    const params = {
      locale: boardmakerToSSOLanguage(i18n.language),
    };

    function getBoardmakerVersions() {
      return axios.get(
        URLS.GET_CURRENT_BY_PRODUCT(
          productDownloadData.find(p => p.key === 'boardmaker')?.sku
        ),
        {
          params,
          headers: {
            'X-API-Version': '3.0',
          },
          data: {
            key: 'boardmaker',
          },
        }
      );
    }
    function getStudioVersions() {
      return axios.get(
        URLS.GET_CURRENT_BY_PRODUCT(
          productDownloadData.find(p => p.key === 'studio')?.sku
        ),
        {
          params,
          headers: {
            'X-API-Version': '3.0',
          },
          data: {
            key: 'studio',
          },
        }
      );
    }
    function getStudentCenterVersions() {
      return axios.get(
        URLS.GET_CURRENT_BY_PRODUCT(
          productDownloadData.find(p => p.key === 'studentCenter')?.sku
        ),
        {
          params,
          headers: {
            'X-API-Version': '3.0',
          },
          data: {
            key: 'studentCenter',
          },
        }
      );
    }
    function getScmpVersions() {
      return axios.get(
        URLS.GET_CURRENT_BY_PRODUCT(
          productDownloadData.find(p => p.key === 'scmp')?.sku
        ),
        {
          params,
          headers: {
            'X-API-Version': '3.0',
          },
          data: {
            key: 'scmp',
          },
        }
      );
    }

    const productVersionsRequests = [
      getBoardmakerVersions(),
      getStudioVersions(),
      getStudentCenterVersions(),
      getScmpVersions(),
    ];

    axios.all(productVersionsRequests).then(response => {
      //* create new array of versions by product
      const productVersions = response.map(pv => {
        const key = JSON.parse(pv.config.data).key;
        return {
          key,
          versions: pv.data,
        };
      });

      //* merge product versions with static data
      const newProducts = products.map(pp => {
        return {
          ...pp,
          ...productVersions.find(p => p.key === pp.key),
        };
      });

      setProducts(newProducts);
    });

    // eslint-disable-next-line
  }, [, i18n.language]);

  return (
    <>
      <SEO title={pageTitle} />
      <div className="row">
        <div className="col-12">
          <PageHeader>{pageTitle}</PageHeader>
        </div>
      </div>
      <DesktopDownloadWrapper>
        <div className="row">
          <div className="col-12">
            <span className="small">{introText}</span>
          </div>
        </div>
        
        <hr className="hr" />
        <div className="row">
          <div className="col-12">
            <ProductToggle
              products={products}
              activeProductIndex={activeProductIndex}
              setActiveProduct={setActiveProduct}
            />
          </div>
        </div>
        {activeProductIndex === -1 && (
          <div className="row">
            <div className="col-10">
              <AccordionTop className="accordion">
                {products
                  .filter(p => p.legacy)
                  .map((p, i) => (
                    <LegacyDownloadDisplay product={p} index={i} />
                  ))}
              </AccordionTop>
            </div>
            <div className="col-2"></div>
          </div>
        )}
        {activeProductIndex !== -1 && (
          <ProductDisplay product={products[activeProductIndex]} type={0} />
        )}
      </DesktopDownloadWrapper>
      <MobileDownloadWrapper>
        <MobileMessage className="d-md-none my-2">
            {compatibleText}
        </MobileMessage>
        <div>
          <Trans i18nKey="common:download.latestMobile">
          For the best experience, please try downloading the software from a device that meets the
            <a 
              href={URLS.BOARDMAKER_REQUIREMENTS} 
              rel="noopener noreferrer" 
              target="_blank"
            >
              {{ systemRequirements: t('common:download.downloadSystemRequirements') }}
            </a> 
            .
          </Trans>
        </div>
      </MobileDownloadWrapper>
    </>
  );
};

export default DownloadsPage;

const AccordionTop = styled.div`
  padding-top: 30px;
`;

const AccordionHeader = styled.div`
  padding: 0px;
`;

const AccordionPanel = styled.div`
  background-color: #fafafa;
`;

const DesktopDownloadWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
`;

const MobileDownloadWrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
`;

const MobileMessage = styled.div` 
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 20px;  
    padding-bottom: 20px;
    background-color: #EFEFF0; 
    color: #231f20;
    text-align: center;
    display: block !important;
`;
