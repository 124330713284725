import i18n from '../i18n/i18n';
import URLS from '@config/urls';

export default [
  {
    sku: process.env.PRODUCT_BOARDMAKER_SKU,
    key: 'boardmaker',
    name: 'Boardmaker',
    productFamily: 'Boardmaker',
    heading: 'Boardmaker 7 Editor',
    legacy: false,
    sortOrder: 0,
    legacyOrder: 0,
    intro: 'download.boardmakerIntro',
    include: {
      key: 'studio',
      afterVersion: 7,
    },
    summary: 'download.boardmakerSummary',
    releaseNotes: URLS.BM7_EDITOR_RELEASE_NOTES,
    requirements: URLS.BOARDMAKER_REQUIREMENTS,
  },
  {
    sku: process.env.PRODUCT_BOARDMAKER_STUDENT_CENTER_SKU,
    key: 'studentCenter',
    name: 'Boardmaker Student Center',
    productFamily: 'Student Center',
    heading: 'Boardmaker 7 Student Center',
    intro: 'download.studentCenterIntro',
    legacy: false,
    sortOrder: 1,
    legacyOrder: 0,
    include: {
      key: 'scmp',
      afterVersion: 7,
    },
    summary: 'download.studentCenterSummary',
    releaseNotes: URLS.BM7_STUDENT_CENTER_RELEASE_NOTES,
    requirements: URLS.BOARDMAKER_REQUIREMENTS,
  },
  {
    sku: process.env.PRODUCT_STUDIO_SKU,
    key: 'studio',
    name: 'Boardmaker Studio',
    heading: 'Boardmaker Studio',
    legacy: true,
    intro: 'download.boardmakerStudioIntro',
    sortOrder: 2,
    legacyOrder: 0,
    versionProps: {
      name: 'Boardmaker Studio',
      anchorKey: 'boardmaker-studio',
      downloadSku: process.env.PRODUCT_STUDIO_SKU,
    },
    summary: 'download.boardmakerStudioSummary',
    releaseNotes: URLS.BOARDMAKER_RELEASE_NOTES,
    requirements: URLS.STUDIO_REQUIREMENTS,
  },
  {
    sku: process.env.PRODUCT_STUDENT_CENTER_SKU,
    key: 'scmp',
    name: 'Boardmaker Student Center Mobile Player',
    heading: 'Boardmaker Student Center v1.8.0',
    legacy: true,
    intro: 'download.mobilePlayerIntro',
    sortOrder: 2,
    legacyOrder: 1,
    versionProps: {
      anchorKey: 'scmp',
      downloadSku: process.env.PRODUCT_STUDENT_CENTER_SKU,
    },
    summary: 'download.mobilePlayerSummary',
    releaseNotes: '',
    requirements: URLS.SCMP_REQUIREMENTS,
  },
];
